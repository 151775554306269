import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Automotive from "../views/dashboards/Automotive.vue";
import SmartHome from "../views/dashboards/SmartHome.vue";
import VRDefault from "../views/dashboards/vr/VRDefault.vue";
import VRInfo from "../views/dashboards/vr/VRInfo.vue";
import CRM from "../views/dashboards/CRM.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Teams from "../views/pages/profile/Teams.vue";
import Projects from "../views/pages/profile/Projects.vue";
import General from "../views/pages/projects/General.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import NewProject from "../views/pages/projects/NewProject.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import Analytics from "../views/applications/analytics/Analytics.vue";
import EcommerceOverview from "../views/ecommerce/overview/Overview.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/EditProduct.vue";
import ProductPage from "../views/ecommerce/ProductPage.vue";
import ProductsList from "../views/ecommerce/ProductsList.vue";
import OrderDetails from "../views/ecommerce/Orders/OrderDetails";
import OrderList from "../views/ecommerce/Orders/OrderList";
import Referral from "../views/ecommerce/Referral";
import Reports from "../views/pages/Users/Reports.vue";
import NewUserCT from "../views/pages/Users/NewUserCT.vue";
import Settings from "../views/pages/Account/Settings.vue";
import Billing from "../views/pages/Account/Billing.vue";
import Invoice from "../views/pages/Account/Invoice.vue";
import Security from "../views/pages/Account/Security.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Login from "../views/auth/signin/Login.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetBasic from "../views/auth/reset/Basic.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SendEmail from "../views/auth/reset/SendEmail.vue";
import ResetPassword from "../views/auth/reset/ResetPassword.vue";
import ResetIllustration from "../views/auth/reset/Illustration.vue";
import VerificationBasic from "../views/auth/verification/Basic.vue";
import VerificationCover from "../views/auth/verification/Cover.vue";
import VerificationIllustration from "../views/auth/verification/Illustration.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import Register from "../views/auth/signup/Register.vue";
import SignupIllustration from "../views/auth/signup/Illustration.vue";
import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import lockBasic from "../views/auth/lock/Basic.vue";
import lockCover from "../views/auth/lock/Cover.vue";
import lockIllustration from "../views/auth/lock/Illustration.vue";
import Profile from "../views/examples/Profile.vue";
import Roles from "../views/examples/Roles/Roles.vue";
import NewRole from "../views/examples/Roles/NewRole.vue";
import EditRole from "../views/examples/Roles/EditRole.vue";
import Users from "../views/examples/Users/Users.vue";
import NewUser from "../views/examples/Users/NewUser.vue";
import EditUser from "../views/examples/Users/EditUser.vue";
import Tags from "../views/examples/Tags/Tags.vue";
import NewTag from "../views/examples/Tags/NewTag.vue";
import EditTag from "../views/examples/Tags/EditTag.vue";
import Categories from "../views/examples/Categories/Categories.vue";
import NewCategory from "../views/examples/Categories/NewCategory.vue";
import EditCategory from "../views/examples/Categories/EditCategory.vue";
import Items from "../views/examples/Items/Items.vue";
import NewItem from "../views/examples/Items/NewItem.vue";
import EditItem from "../views/examples/Items/EditItem.vue";
import admin from "../middlewares/admin.js";
import adminCreator from "../middlewares/admin_creator.js";
import guest from "../middlewares/guest.js";
import auth from "../middlewares/auth.js";
import Vorsorgeaufstellung from "@/views/applications/vorsorgeaufstellung/Vorsorgeaufstellung.vue";
import MeetingsList from "@/views/meetings/MeetingsList.vue";
import MeetingVideoPage from "@/views/meetings/MeetingVideoPage.vue";
import NewMeeting from "@/views/meetings/new/NewMeeting.vue";
import EditMeeting from "@/views/meetings/edit/EditMeeting.vue";
import ClearUpList from "@/views/pages/beratung/renten-clearup/ClearUpList.vue";
import RentenClearUpSinglePage from "@/views/pages/beratung/renten-clearup/ClearUpSinglePage.vue";
import RentenClearUpEdit from "@/views/pages/beratung/renten-clearup/ClearUpEdit.vue";
import ClearUpNew from "@/views/pages/beratung/renten-clearup/ClearUpNew.vue";
import AnwerbungList from "@/views/pages/anwerbung/AnwerbungList.vue";
import AnwerbungEdit from "@/views/pages/anwerbung/AnwerbungEdit.vue";
import AnwerbungNew from "@/views/pages/anwerbung/AnwerbungNew.vue";
import Anwerbung from "@/views/pages/anwerbung/Anwerbung.vue";
import SonstigesEdit from "@/views/pages/beratung/sontiges/SonstigesEdit.vue";
import Sonstiges from "@/views/pages/beratung/sontiges/Sonstiges.vue";
import SonstigesList from "@/views/pages/beratung/sontiges/SonstigesList.vue";
import SonstigesNew from "@/views/pages/beratung/sontiges/SonstigesNew.vue";
import EinarbeitungList from "@/views/pages/ausbildung/einarbeitung/EinarbeitungList.vue";
import EinarbeitungEdit from "@/views/pages/ausbildung/einarbeitung/EinarbeitungEdit.vue";
import EinarbeitungNew from "@/views/pages/ausbildung/einarbeitung/EinarbeitungNew.vue";
import Einarbeitung from "@/views/pages/ausbildung/einarbeitung/Einarbeitung.vue";
import UnternehmenspraesentationList
  from "@/views/pages/ausbildung/unternehmenspraesentation/UnternehmenspraesentationList.vue";
import UnternehmenspraesentationEdit
  from "@/views/pages/ausbildung/unternehmenspraesentation/UnternehmenspraesentationEdit.vue";
import UnternehmenspraesentationNew
  from "@/views/pages/ausbildung/unternehmenspraesentation/UnternehmenspraesentationNew.vue";
import Unternehmenspraesentation
  from "@/views/pages/ausbildung/unternehmenspraesentation/Unternehmenspraesentation.vue";
import RentenClearUpErklaerVideoList
  from "@/views/pages/beratung/renten-clearup/erklaervideo/RentenClearUpErklaerVideoList.vue";
import RentenClearUpErklaerVideoEdit
  from "@/views/pages/beratung/renten-clearup/erklaervideo/RentenClearUpErklaerVideoEdit.vue";
import RentenClearUpErklaerVideoNew
  from "@/views/pages/beratung/renten-clearup/erklaervideo/RentenClearUpErklaerVideoNew.vue";
import RentenClearUpErklaerVideoSinglePage
  from "@/views/pages/beratung/renten-clearup/erklaervideo/RentenClearUpErklaerVideoSinglePage.vue";
import AktuellesList from "@/views/pages/aktuelles/AktuellesList.vue";
import AktuellesEdit from "@/views/pages/aktuelles/AktuellesEdit.vue";
import AktuellesNew from "@/views/pages/aktuelles/AktuellesNew.vue";
import Aktuelles from "@/views/pages/aktuelles/Aktuelles.vue";
import VorsorgeaufstellungenListe from "@/views/applications/vorsorgeaufstellung/list/VorsorgeaufstellungenListe.vue";
import ClearUpToolList from "@/views/pages/beratung/renten-clearup/tool/ClearUpToolList.vue";
import ClearUpToolEdit from "@/views/pages/beratung/renten-clearup/tool/ClearUpToolEdit.vue";
import ClearUpToolNew from "@/views/pages/beratung/renten-clearup/tool/ClearUpToolNew.vue";
import ClearUpToolSinglePage from "@/views/pages/beratung/renten-clearup/tool/ClearUpToolSinglePage.vue";
import AbrechnungList from "@/views/pages/aktuelles/abrechnung/AbrechnungList.vue";
import AbrechnungEdit from "@/views/pages/aktuelles/abrechnung/AbrechnungEdit.vue";
import AbrechnungNew from "@/views/pages/aktuelles/abrechnung/AbrechnungNew.vue";
import Abrechnung from "@/views/pages/aktuelles/abrechnung/Abrechnung.vue";
import EmpfehlungList from "@/views/pages/ausbildung/empfehlungsmanagement/EmpfehlungList.vue";
import EmpfehlungEdit from "@/views/pages/ausbildung/empfehlungsmanagement/EmpfehlungEdit.vue";
import EmpfehlungNew from "@/views/pages/ausbildung/empfehlungsmanagement/EmpfehlungNew.vue";
import Empfehlung from "@/views/pages/ausbildung/empfehlungsmanagement/Empfehlung.vue";

const routes = [
  {
    path: "/",
    name: "Default",
    component: Default,
  },
  /**
   * Aktuelles
   */
  {
    path: "/aktuelles",
    name: "AktuellesList",
    component: AktuellesList,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/aktuelles/edit/:id",
    name: "AktuellesEdit",
    component: AktuellesEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/aktuelles/new",
    name: "AktuellesNew",
    component: AktuellesNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/aktuelles/:id",
    name: "Aktuelles",
    component: Aktuelles,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Aktuelle Abrechnung
   */
  {
    path: "/aktuelles/abrechnung",
    name: "AbrechnungList",
    component: AbrechnungList,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/aktuelles/abrechnung/edit/:id",
    name: "AbrechnungEdit",
    component: AbrechnungEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/aktuelles/abrechnung/new",
    name: "AbrechnungNew",
    component: AbrechnungNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/aktuelles/abrechnung/:id",
    name: "Abrechnung",
    component: Abrechnung,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Empfehlungsmanagement
   */
  {
    path: "/ausbildung/empfehlung",
    name: "EmpfehlungList",
    component: EmpfehlungList,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/ausbildung/empfehlung/edit/:id",
    name: "EmpfehlungEdit",
    component: EmpfehlungEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ausbildung/empfehlung/new",
    name: "EmpfehlungNew",
    component: EmpfehlungNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ausbildung/empfehlung/:id",
    name: "Empfehlung",
    component: Empfehlung,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Ausbildung Unternehmenspräsentation
   */
  {
    path: "/ausbildung/unternehmenspraesentation",
    name: "UnternehmenspraesentationList",
    component: UnternehmenspraesentationList,
  },
  {
    path: "/ausbildung/unternehmenspraesentation/edit/:id",
    name: "UnternehmenspraesentationEdit",
    component: UnternehmenspraesentationEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ausbildung/unternehmenspraesentation/new",
    name: "UnternehmenspraesentationNew",
    component: UnternehmenspraesentationNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ausbildung/unternehmenspraesentation/:id",
    name: "Unternehmenspraesentation",
    component: Unternehmenspraesentation,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Ausbildung Einarbeitung
   */
  {
    path: "/ausbildung/einarbeitung",
    name: "EinarbeitungList",
    component: EinarbeitungList,
  },
  {
    path: "/ausbildung/einarbeitung/edit/:id",
    name: "EinarbeitungEdit",
    component: EinarbeitungEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ausbildung/einarbeitung/new",
    name: "EinarbeitungNew",
    component: EinarbeitungNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ausbildung/einarbeitung/:id",
    name: "Einarbeitung",
    component: Einarbeitung,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Beratung Sonstiges
   */
  {
    path: "/beratung/sonstiges",
    name: "SonstigesList",
    component: SonstigesList,
  },
  {
    path: "/beratung/sonstiges/edit/:id",
    name: "SonstigesEdit",
    component: SonstigesEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/sonstiges/new",
    name: "SonstigesNew",
    component: SonstigesNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/sonstiges/:id",
    name: "Sonstiges",
    component: Sonstiges,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Anwerbung
   */
  {
    path: "/anwerbung/neue-talente",
    name: "AnwerbungList",
    component: AnwerbungList,
  },
  {
    path: "/anwerbung/neue-talente/edit/:id",
    name: "AnwerbungEdit",
    component: AnwerbungEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/anwerbung/neue-talente/new",
    name: "AnwerbungNew",
    component: AnwerbungNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/anwerbung/neue-talente/:id",
    name: "Anwerbung",
    component: Anwerbung,
    meta: {
      middleware: [auth],
    },
  },
  /**
   * Beratung
   */
  {
    path: "/beratung/renten-clearup",
    name: "ClearUpList",
    component: ClearUpList,
  },
  {
    path: "/beratung/renten-clearup/edit/:id",
    name: "RentenClearUpEdit",
    component: RentenClearUpEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/renten-clearup/new",
    name: "ClearUpNew",
    component: ClearUpNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/renten-clearup/:id",
    name: "RentenClearUp",
    component: RentenClearUpSinglePage,
    meta: {
      middleware: [auth],
    },
  },
    // clear up tool
  {
    path: "/beratung/renten-clearup-tool",
    name: "ClearUpToolList",
    component: ClearUpToolList,
  },
  {
    path: "/beratung/renten-clearup-tool/edit/:id",
    name: "RentenClearUpToolEdit",
    component: ClearUpToolEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/renten-clearup-tool/new",
    name: "ClearUpToolNew",
    component: ClearUpToolNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/renten-clearup-tool/:id",
    name: "RentenClearUpTool",
    component: ClearUpToolSinglePage,
    meta: {
      middleware: [auth],
    },
  },
    // erklär video clear up
  {
    path: "/beratung/renten-clearup-erklaervideo",
    name: "RentenClearUpErklaerVideoList",
    component: RentenClearUpErklaerVideoList,
  },
  {
    path: "/beratung/renten-clearup-erklaervideo/edit/:id",
    name: "RentenClearUpErklaerVideoEdit",
    component: RentenClearUpErklaerVideoEdit,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/renten-clearup-erklaervideo/new",
    name: "RentenClearUpErklaerVideoNew",
    component: RentenClearUpErklaerVideoNew,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/beratung/renten-clearup-erklaervideo/:id",
    name: "RentenClearUpErklaerVideo",
    component: RentenClearUpErklaerVideoSinglePage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/dashboards/automotive",
    name: "Automotive",
    component: Automotive,
  },
  {
    path: "/dashboards/smart-home",
    name: "Smart Home",
    component: SmartHome,
  },
  {
    path: "/dashboards/vr/vr-default",
    name: "VR Default",
    component: VRDefault,
  },
  {
    path: "/dashboards/vr/vr-info",
    name: "VR Info",
    component: VRInfo,
  },
  {
    path: "/dashboards/crm",
    name: "CRM",
    component: CRM,
  },
  {
    path: "/pages/profile/overview",
    name: "Profile Overview",
    component: Overview,
  },
  {
    path: "/pages/profile/teams",
    name: "Teams",
    component: Teams,
  },
  {
    path: "/pages/profile/projects",
    name: "All Projects",
    component: Projects,
  },
  {
    path: "/pages/projects/general",
    name: "General",
    component: General,
  },
  {
    path: "/pages/projects/timeline",
    name: "Timeline",
    component: Timeline,
  },
  {
    path: "/pages/projects/new-project",
    name: "New Project",
    component: NewProject,
  },
  {
    path: "/pages/pricing-page",
    name: "Pricing Page",
    component: Pricing,
  },
  {
    path: "/pages/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/pages/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/pages/widgets",
    name: "Widgets",
    component: Widgets,
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/applications/kanban",
    name: "Kanban",
    component: Kanban,
  },
  {
    path: "/beratung/vorsorgeaufstellung",
    name: "Vorsorgeaufstellung",
    component: Vorsorgeaufstellung,
  },
  {
    path: "/beratung/vorsorgeaufstellung/list",
    name: "Vorsorgeaufstellungen",
    component: VorsorgeaufstellungenListe,
  },
  {
    path: "/applications/data-tables",
    name: "Data Tables",
    component: DataTables,
  },
  {
    path: "/applications/calendar",
    name: "Calendar",
    component: Calendar,
  },
  {
    path: "/applications/analytics",
    name: "Analytics",
    component: Analytics,
  },
  {
    path: "/ecommerce/overview",
    name: "Overview",
    component: EcommerceOverview,
  },
  {
    path: "/ecommerce/products/new-product",
    name: "New Product",
    component: NewProduct,
  },
  {
    path: "/ecommerce/products/edit-product",
    name: "Edit Product",
    component: EditProduct,
  },
  {
    path: "/ecommerce/products/product-page",
    name: "Product Page",
    component: ProductPage,
  },
  {
    path: "/ecommerce/products/products-list",
    name: "Products List",
    component: ProductsList,
  },
  {
    path: "/meetings",
    name: "Meetings",
    component: MeetingsList,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/meetings/:id",
    name: "Meeting",
    component: MeetingVideoPage,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/meetings/new",
    name: "New Meeting",
    component: NewMeeting,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/meetings/edit/:id",
    name: "Edit Meeting",
    component: EditMeeting,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/ecommerce/Orders/order-details",
    name: "Order Details",
    component: OrderDetails,
  },
  {
    path: "/ecommerce/Orders/order-list",
    name: "Order List",
    component: OrderList,
  },
  {
    path: "/ecommerce/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/pages/users/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/pages/users/new-user",
    name: "New User CT",
    component: NewUserCT,
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
  },
  {
    path: "/pages/account/Security",
    name: "Security",
    component: Security,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/basic",
    name: "Reset Basic",
    component: ResetBasic,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/reset/illustration",
    name: "Reset Illustration",
    component: ResetIllustration,
  },
  {
    path: "/authentication/lock/basic",
    name: "Lock Basic",
    component: lockBasic,
  },
  {
    path: "/authentication/lock/cover",
    name: "Lock Cover",
    component: lockCover,
  },
  {
    path: "/authentication/lock/illustration",
    name: "Lock Illustration",
    component: lockIllustration,
  },
  {
    path: "/authentication/verification/basic",
    name: "Verification Basic",
    component: VerificationBasic,
  },
  {
    path: "/authentication/verification/cover",
    name: "Verification Cover",
    component: VerificationCover,
  },
  {
    path: "/authentication/verification/illustration",
    name: "Verification Illustration",
    component: VerificationIllustration,
  },
  {
    path: "/authentication/signup/basic",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/authentication/signup/illustration",
    name: "Signup Illustration",
    component: SignupIllustration,
  },
  {
    path: "/error/not-found",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/error/error500",
    name: "Error Error500",
    component: Error500,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/password/email",
    name: "SendEmail",
    component: SendEmail,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/settings/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/examples/roles/list",
    name: "Roles",
    component: Roles,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/examples/roles/new",
    name: "New Role",
    component: NewRole,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/examples/roles/edit/:id",
    name: "Edit Role",
    component: EditRole,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/management/users/list",
    name: "Users",
    component: Users,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/management/users/new",
    name: "New User",
    component: NewUser,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/management/users/edit/:id",
    name: "Edit User",
    component: EditUser,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/examples/tags/list",
    name: "Tags",
    component: Tags,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/tags/new",
    name: "New Tag",
    component: NewTag,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/tags/edit/:id",
    name: "Edit Tag",
    component: EditTag,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/categories/list",
    name: "Categories",
    component: Categories,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/categories/new",
    name: "New Category",
    component: NewCategory,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/categories/edit/:id",
    name: "Edit Category",
    component: EditCategory,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/items/list",
    name: "Items",
    component: Items,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/items/new",
    name: "New Item",
    component: NewItem,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: "/examples/items/edit/:id",
    name: "Edit Item",
    component: EditItem,
    meta: {
      middleware: [auth, adminCreator],
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/error/not-found',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});


function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}


router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    to.meta.middleware = [auth];
  }

  const middleware = to.meta.middleware;

  const context = {
    from,
    next,
    router,
    to,
  };
  const nextMiddleware = nextFactory(context, middleware, 1);

  return middleware[0]({ ...context, next: nextMiddleware });
});

export default router;
