<script>
import CMSListCard from "@/views/components/cms/CMSListCard.vue";

export default {
  name: "AbrechnungList",
  components: {CMSListCard},
  data() {
    return {
      tableItems: null,
      tableHeader: [
        {
          title: "article",
          label: "Titel",
        },
        {
          title: "creator",
          label: "Erstellt von",
        },
        {
          title: "description",
          label: "Beschreibung",
        },
        {
          title: "Action",
          label: "Aktionen",
        }
      ]
    };
  },
}
</script>

<template>
  <CMSListCard
      title="Aktuelle Abrechnung"
      new-button-name="Neue Abrechnung"
      :list-header="tableHeader"
      :category=11
      :event-to-call-options="{
        table: tableItems,
        showPath: 'Abrechnung',
        redirectPath: 'AbrechnungEdit',
        categoryId: 11,
        deletePath: 'cms/deleteArticle',
        getPath: 'cms/getArticlesByCategory',
        textDelete: 'Abrechnung wurde erfolgreich gelöscht!',
        textDefaultData: 'abrechnung'
      }"
   new-button-path="AbrechnungNew"></CMSListCard>
</template>

<style scoped>

</style>
